import React from 'react';
import Handle404 from '../Components/Error/Handle404';
import HeadError from '../Components/Error/HeadError';


const Error404 = () => {

    return (
        <>
        <HeadError/>
        <Handle404/>
        </>
    );
}

export default Error404;
