import LazyLoad from 'react-lazyload';
import Image from 'next/image';

const HeadError = () => {
    return ( 
        <nav className="fixed top-0 left-0 bg-secondary w-full z-50">
            <div className="flex justify-center max-w-screen-lg mx-auto lg:justify-start items-center h-16">
                    <LazyLoad once >
                        <Image loading="lazy" 
                        src='https://cdn.fixat.mx/assets/img/no-webp/logo-principal-fixat.png'
                        alt="Fixat® | Declaramos tus impuestos" 
                        title="Fixat® | Declaramos tus impuestos"
                        height="44px"
                        width="169px"
                        />
                    </LazyLoad>
            </div>
        </nav>
    );
}
 
export default HeadError;