import LazyLoad from 'react-lazyload';
import Image from 'next/image';
import BackHome from './BackHome';
const Handle404 = () => {
    return ( 
        <>
        <div className="flex flex-col h-screen bg-gradient-to-b from-gradient-blue to-gradient-clear ">
        <div className="flex flex-1 items-center bg-error-bg bg-cover bg-no-repeat bg-bottom px-0 lg:px-8">
        <div className="flex flex-wrap md:flex-nowrap  max-w-screen-lg mx-auto justify-center md:justify-between w-full text-center md:text-left">
            <div className="flex flex-col w-full sm:w-2/3 py-16">
                <span className="font-fredoka text-5xl md:text-8xl text-error mb-4 mt-8">ERROR 404</span>
                <span className="font-medium">
                    <p className="text-4xl md:text-5xl text-FixBlue mb-4 md:mb-16">¡Página no encontrada!</p>
                    <p className="text-xl text-error-message mb-1/3">Esta página  que estás buscando no está disponible :(</p>
                </span>
            </div>
            <div className="flex w-auto justify-end py-0 md:py-20">
            <LazyLoad once>
                <Image loading="lazy" 
                src='https://cdn.fixat.mx/assets/errors/error-404@1x.png' 
                alt="Fixat® | Error 404"
                title="Fixat® | Error 404"
                height="469px"
                width="360px"
                />
            </LazyLoad>
            </div>
        </div>
        </div>
            <BackHome/>
        </div>
        </>
    );
}
 
export default Handle404;