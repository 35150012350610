import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';

const BackHome = () => {
    return ( 
            <div className="bg-pastel-blue text-center md:text-left flex px-8 lg:px-0">
                    <p className="text-black font-bold w-full max-w-screen-lg mx-auto pb-11 pt-6"><FontAwesomeIcon width="38px" className="mr-4" icon={faLongArrowAltLeft}/>
                        <Link rel='follow'  href="/" passHref>
                            <a rel='follow' >
                                Regresar al Home
                            </a>
                        </Link>
                    </p>
            </div>
    );
}
 
export default BackHome;